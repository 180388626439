<!-- @format -->

<template>
  <div>
    <MobileHeader :social="true" />
    <MobileTop :kinostart="kinostart" class="mob-top" id="mob-top" />

    <MobileTrailerSizzle />
    <div class="mobile__packshot" :class="$mq">
      <a
        href="https://www.amazon.de/-/de/Gunda/dp/B09CRLZLGQ/ref=sr_1_3?dchild=1&keywords=Gunda+DVD&qid=1633335459&qsid=262-1034553-2302661&sr=8-3&sres=B09CRLZLGQ%2CB01M3S2HM1%2CB00ABHQFS8%2CB006XEU6VA%2CB08BG5H8XY%2CB00EZMEHQ2%2C3110486814%2CB01MYULCEC%2CB00AAZ3THG%2CB01LP5FCP6%2CB07KZ4H9KR%2CB07BZC8LBB%2CB0785MQP8W%2CB01M4N0UCH%2CB07T2BGV5V%2CB00QV47KM0"
        target="_blank"
        ><img src="@/assets/images/packshot.jpg" alt=""
      /></a>
    </div>
    <div class="btns">
      <div class="amazon">
        <a
          href="https://www.amazon.de/Gunda/dp/B09CRLZLGQ/ref=sr_1_2?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&dchild=1&keywords=Gunda+DVD&qid=1635174223&qsid=262-1034553-2302661&s=dvd&sr=1-2&sres=B09CRLZLGQ%2CB09794X5RG%2CB01M3S2HM1%2CB00ABHQFS8%2CB00ABHQFW4%2CB017RGO61S%2CB006XEU6VA%2CB08BG5H8XY%2CB00EZMEHQ2%2CB07T2BGV5V%2CB01MPWGLUW%2CB00AAZ3THG%2CB07H2Z3PLV%2CB01M4N0UCH%2CB01MXVVDXG%2CB01LP5FCP6"
          target="_blank"
          ><img src="@/assets/images/amazon_btn.png" alt=""
        /></a>
      </div>
      <div class="itunes">
        <a
          href="https://tv.apple.com/de/movie/gunda/umc.cmc.6qm3lmiek1mvla9c400e9aljb"
          target="_blank"
          ><img src="@/assets/images/itunes_btn.png" alt=""
        /></a>
      </div>
    </div>
    <div class="mobile__stoerer" :class="$mq">
      <a href="download/Gunda_Bildungsmaterial.pdf" target="_blank"
        ><img src="@/assets/images/mobile_download.jpg" alt=""
      /></a>
    </div>
    <!--    <div class="content">
      
      <div class="cites">
        <div class="text">„Ein hervorragender Dokumentarfilm“</div>
        <div class="autor">ttt -Titel, Thesen, Temperamente</div>
      </div>
      <div class="inhalt-top">
        <div class="heading">
          <div class="content">
            <div class="cell">3 Schauspielerinnen</div>
            <div class="cell">3 Kinderzimmer</div>
            <div class="cell">10 Tage</div>
            <div class="cell">2.458 Männer</div>
            <div class="cline">mit eindeutigen Absichten</div>
          </div>
        </div>
      </div>
    </div>-->
    <!-- Inhalt -->
    <div id="mob-inhalt">
      <MobileExpandableCard
        class="mob-inhalt"
        image="card_inhalt.jpg"
        btnTopAlign="left"
        btnTopColor="#b2b3b6"
        btnTopBg="#fff"
        btnBottomColor="#b2b3b6"
        btnBottomBg="#fff"
        cardBorderRadius="0px"
        imageAlign="center"
      >
        <!-- H1 Headline -->
        <template v-slot:headline> Inhalt </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <div v-html="$texte.inhalt.teaser"></div>
        </template>
        <!-- Hidden Maincontent -->
        <div v-html="$texte.inhalt.text"></div>
      </MobileExpandableCard>
    </div>
    <div class="bild1" :class="$mq">
      <video src="@/assets/videos/mobile_vie.mp4" loop muted autoplay></video>
    </div>
    <div id="mob-crew">
      <MobileExpandableCard
        class="mob-crew"
        image="card_crew.jpg"
        btnTopAlign="left"
        btnTopColor="#b2b3b6"
        btnTopBg="#fff"
        btnBottomColor="#b2b3b6"
        btnBottomBg="#fff"
        cardBorderRadius="0px"
        imageAlign="center"
      >
        <!-- H1 Headline -->
        <template v-slot:headline> Crew </template>
        <!-- Teaser -->
        <template v-slot:teaser>
          <div v-html="$texte.crew.teaser"></div>
        </template>
        <!-- Hidden Maincontent -->
        <crew-mobile />
      </MobileExpandableCard>
    </div>
    <div class="mobile-cline">
      <img src="@/assets/images/mobile/mobile_cline.jpg" />
    </div>
    <div id="mob-fotos" class="mob-fotos">
      <BaseCarousel
        :autoplay="false"
        :preview="true"
        :fade="true"
        :fullscreen="true"
        :dots="true"
      >
        <div class="source">
          <div
            v-for="value in this.$config.gallerymobile.count"
            :key="value"
            class="carousel-cell"
          >
            <img
              :src="require(`@/assets/images/gallery/mobile/` + value + `.jpg`)"
              alt
              class="pictures"
            />
          </div>
        </div>
      </BaseCarousel>
    </div>
    <!--    <div class="fotos">
      <div class="image_grid">
        <div class="col">
          <div class="fullpic">
            <img src="@/assets/images/mobile/pic1.jpg" @click="foto = 0" />
          </div>
          <div class="row">
            <div class="pic">
              <img src="@/assets/images/mobile/pic2.jpg" @click="foto = 1" />
            </div>
            <div class="pic">
              <img src="@/assets/images/mobile/pic3.jpg" @click="foto = 2" />
            </div>
          </div>
          <div class="fullpic">
            <img src="@/assets/images/mobile/pic4.jpg" @click="foto = 3" />
          </div>
          <div class="row">
            <div class="pic">
              <img src="@/assets/images/mobile/pic5.jpg" @click="foto = 4" />
            </div>
            <div class="pic">
              <img src="@/assets/images/mobile/pic6.jpg" @click="foto = 5" />
            </div>
          </div>
          <div class="fullpic">
            <img src="@/assets/images/mobile/pic7.jpg" @click="foto = 6" />
          </div>
        </div>
      </div>
    </div>-->

    <BaseFooter :kinostart="kinostart" :billing="true" :youtubenote="false" />
    <BaseModalGallery :foto="foto" />
  </div>
</template>

<script>
  import moment from 'moment'

  import texte from '@/views/texte/all.json'
  import CrewMobile from '../views/CrewMobile.vue'

  export default {
    components: { CrewMobile },
    data() {
      return {
        foto: null,
        images: [],
        texte: texte,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      ratio() {
        return (
          this.$store.getters['Window/isSize'].width /
          this.$store.getters['Window/isSize'].height
        )
      },
      trailer() {
        return this.$config.youtube.id !== ''
      },
    },
    mounted() {
      for (let index = 0; index < this.$config.gallery.count; index++) {
        this.images[index] = require(`@/assets/images/gallery/` +
          this.pad(index + 1, 1) +
          `.jpg`)
      }
    },
    methods: {
      pad(num, size) {
        let s = num + ''
        while (s.length < size) s = '0' + s
        return s
      },
    },
  }
</script>

<style lang="scss">
  body {
    background-color: #030204;
  }

  .inhalt-top {
    display: flex;
    justify-content: center;
    width: 100%;
    .heading {
      font-family: 'Lora';
      width: 1500px;
      color: $secondary;
      .content {
        text-align: center;
        .cell {
          font-size: 25px;
        }
        .cline {
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
  .btns {
    display: flex;
    flex-direction: row;
    padding: 20px 0 10px 0;
    .amazon {
      padding-left: 7.8%;
      padding-right: 1%;
    }
    .itunes {
      padding-left: 1%;
      padding-right: 7.8%;
    }
  }
  .cites {
    text-align: center;
    color: white;
    margin-bottom: 10px;
    font-family: 'Lora';
    background-color: black;
    padding-top: 10px;
    .text {
      font-size: 25px;
      color: $secondary;
    }
    .autor {
      font-size: 14px;
      padding-bottom: 20px;
    }
  }
  .bild1 {
    --padding: 5%;
    margin: 30px auto 0 auto;
    position: relative;
    transition-property: padding, height, transform, border-radius,
      -webkit-transform;
    transition-duration: 1s;
    background-color: transparent;
    overflow-y: scroll;
    max-width: 770px;
    video {
      width: 100%;
    }
  }
  .mobile-cline {
    margin-top: 30px;
  }
  /*  .fotos {
    position: relative;
    width: 100%;
    height: auto; // z-index: 1;
    padding-bottom: 0;
    padding-top: 40px;
    .image_grid {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;

      img {
        cursor: pointer;
      }

      .col {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .row {
          display: flex;
          flex-direction: row;
        }

        .pic {
          width: 50%;
          overflow: hidden;
          a {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  } */
</style>
