<!-- @format -->

<template>
  <div>
    <div class="mobile-cast">
      <div class="crew-mobile-text">
        <div class="mobile-person" :class="$mq">
          <span id="mob-span">Victor Kossakovsky</span><br />
          Buch, Regie & Schnitt
        </div>
        <div class="mobile-person" :class="$mq">
          <span id="mob-span">Anita Rehoff Larsen</span><br />
          Produzentin
        </div>
        <div class="mobile-person" :class="$mq">
          <span id="mob-span">Joaquin Phoenix, Tone Grøttjord-Glenne</span
          ><br />
          Ausführende Produzenten
        </div>
        <div class="mobile-person" :class="$mq">
          <span id="mob-span">Joslyn Barnes & Susan Rockefeller</span><br />
          Koproduzentinnen
        </div>
        <div class="mobile-person" :class="$mq">
          <span id="mob-span">Egil Haskjold Larsen & Victor Kossakovsky</span
          ><br />
          Kamera
        </div>
        <div class="mobile-person" :class="$mq">
          <span id="mob-span">Alexandr Dudarev</span><br />
          Sound-Design & -Schnitt
        </div>
      </div>
      <router-link to="/mobile" class="mobile-cast__btn-close">
        <Arrow /> Zurück zur Webseite
      </router-link>
    </div>
  </div>
</template>

<script>
  import Arrow from 'vue-material-design-icons/ArrowLeftBoldCircle'
  import moment from 'moment'

  export default {
    components: {
      Arrow,
    },
    data() {
      return {
        num: 0,
      }
    },
    computed: {
      kinostart() {
        return moment() < moment(this.$config.release_line_1.date)
          ? this.$config.release_line_1.line_before
          : this.$config.release_line_1.line_after
      },
      active() {
        return `fontWeight: 900 !important;
                    color: black;`
      },
    },
  }
</script>

<style lang="scss">
  .mobile-cast {
    $self: &;
    margin-top: 15px;

    .crew-mobile-text {
      font-family: 'Lora';
      text-align: left;
      .mobile-person {
        margin-top: 19px;
        #mob-span {
          font-weight: 700;
          font-size: 16px !important;
        }
      }
      .mobile-bild-crew {
        margin: 20px auto 0 auto;
        width: 70%;
        left: 50%;
        img {
          width: 100%;
        }
      }
    }

    &__btn-close {
      padding: 0 5%;
      color: white;
      font-weight: 700;
    }
  }
</style>
